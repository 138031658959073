body {
    font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    /*width: 240px;*/
    width: 500px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    max-height: 250px;
    overflow-y: auto;
    display: block;
    position: absolute;
    top: 51px;
    /*width: 280px;*/
    width: 500px;
    padding-right: 20px;
    padding-left: 20px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: block;
}

.react-autosuggest__suggestions-list.show {
    display: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    /*padding: 10px 20px;*/
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    font-size: 12px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
    border-top: 0;
}

.react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: #777;
}

.react-autosuggest__section-title .title.show::after {
    content: "\25b2";
    padding-left: 0.5em;
    color: #ff8300;
}

.react-autosuggest__section-title .title.hide::after {
    content: "\25bc";
    padding-left: 0.5em;
    color: #ff8300;
}

.react-autosuggest__section-title:hover {
    background-color: #5fc6ff;
}
