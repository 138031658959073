@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500,600,700,900");

body {
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

html {
    overflow-x: hidden;
}

/* Body is set to hidden when a dropdown is open */
body {
    overflow: visible !important;
}

.infoBox {
    width: auto !important;
}

.highlightRow {
    background-color: rgb(255, 255, 255);
}

.highlightRow p {
    text-align: left;
}

.highlightRow span {
    text-align: center !important;
    display: block;
}

.highlightRow:hover {
    background-color: #eeeeee;
}

.recharts-tooltip-wrapper .custom-tooltip {
    background-color: hsla(0, 0%, 100%, 0.8);
    border: 1px solid #f5f5f5;
    line-height: 24px;
    margin: 0;
    padding: 10px;
    width: 200px;
}

.recharts-tooltip-wrapper .custom-tooltip .label {
    color: #666;
    font-weight: 700;
    margin: 0;
}

.recharts-tooltip-wrapper .custom-tooltip .desc {
    color: #999;
    margin: 0;
}

.recharts-tooltip-wrapper .custom-tooltip .intro {
    border-top: 1px solid #f5f5f5;
    margin: 0;
}
